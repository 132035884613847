import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CurrencyDTO } from 'src/app/Models/currency-dto';
import { HttpHelperService } from 'src/app/Services/http-helper.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-createcurrency',
  templateUrl: './createcurrency.component.html',
  styleUrls: ['./createcurrency.component.sass']
})
export class CreatecurrencyComponent implements OnInit {

  constructor(private serv: HttpHelperService) { }
currency: CurrencyDTO;
  ngOnInit(): void {
     this.currency = new CurrencyDTO();
  }
  submitForm() {
    console.log('Saved successfully..');
    console.log(this.currency);
    this.serv.createcurrency(this.currency).subscribe (data => {
      Swal.fire('Success', this.currency.name + ' was successfully created', 'success');
    },
    err => {
      Swal.fire('error', 'attempt to create ' + this.currency.name + 'failed..', 'error');
    }
    );
  }
  }

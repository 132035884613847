import { Component, OnInit } from '@angular/core';
import { Payinreq } from 'src/app/Models/payinreq';
import { HttpHelperService } from 'src/app/Services/http-helper.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-payin',
  templateUrl: './payin.component.html',
  styleUrls: ['./payin.component.sass']
})
export class PayinComponent implements OnInit {  constructor(private serv: HttpHelperService) { }
payin: Payinreq;
  ngOnInit(): void {
     this.payin = new Payinreq();
  }
  submitForm() {
   // console.log('Saved successfully..');
    console.log(this.payin);
    this.serv.createpayin(this.payin).subscribe (data => {
      Swal.fire('Success', 'Please pay with '+ data.redirect_url, 'success');
    },
    err => {
      Swal.fire('error', 'attempt to generate payment link failed', 'error');
    }
    );
  }
  }

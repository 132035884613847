import { Component, OnInit } from '@angular/core';
import { HttpHelperService } from '../../Services/http-helper.service';
import { CurrencyDTO } from '../../Models/currency-dto';

@Component({
  selector: 'app-createcurrencypage',
  templateUrl: './createcurrencypage.component.html',
  styleUrls: ['./createcurrencypage.component.sass']
})
export class CreatecurrencypageComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {

  }

}

<aside class="sidebar" data-trigger="scrollbar">
  <!-- Sidebar Profile Start -->
  <div class="sidebar--profile">
      <div class="profile--img">
          <a href="profile.html">
              <img src="assets/img/avatars/01_80x80.png" alt="" class="rounded-circle">
          </a>
      </div>

      <div class="profile--name">
          <a href="profile.html" class="btn-link">Errand Master</a>
      </div>

      <div class="profile--nav">
          <ul class="nav">
              <li class="nav-item">
                  <a href="profile.html" class="nav-link" title="User Profile">
                      <i class="fa fa-user"></i>
                  </a>
              </li>
              <li class="nav-item">
                  <a href="lock-screen.html" class="nav-link" title="Lock Screen">
                      <i class="fa fa-lock"></i>
                  </a>
              </li>
              <li class="nav-item">
                  <a href="mailbox_inbox.html" class="nav-link" title="Messages">
                      <i class="fa fa-envelope"></i>
                  </a>
              </li>
              <li class="nav-item">

                  <a  [routerLink]="['']" class="nav-link" title="Logout">
                      <i class="fa fa-sign-out-alt"></i>
                  </a>
              </li>
          </ul>
      </div>
  </div>
  <!-- Sidebar Profile End -->

  <!-- Sidebar Navigation Start -->
  <div class="sidebar--nav">
      <ul>
          <li>
              <ul>
                  <li class="active">
                      <a href="index.html">
                          <i class="fa fa-home"></i>
                          <span>Dashboard</span>
                      </a>
                  </li>
                  <li>
                      <a href="#">
                          <i class="fa fa-shopping-cart"></i>
                          <span>User Manager</span>
                      </a>

                      <ul>
                          <li><a href="#">Create User</a></li>
                          <li><a href="#">View Users</a></li>
                          <li><a href="#">Manage Role</a></li>
                          <li><a href="#">Remove User</a></li>
                      </ul>
                  </li>
              </ul>
          </li>

          <li>
              <a href="#">Administration</a>

              <ul>
                  <li>
                      <a href="#">
                          <i class="fa fa-th"></i>
                          <span>Errand Management</span>
                      </a>

                      <ul>
                          <li><a href="#">Create Currency</a></li>
                          <li><a href="#">Create </a></li>

                      </ul>
                  </li>
                  <li>
                      <a href="#">
                          <i class="fa fa-th-list"></i>
                          <span>ErrandPay</span>
                      </a>

                      <ul>
                          <li><a href="footer-light.html">Footer Light</a></li>
                          <li><a href="footer-dark.html">Footer Dark</a></li>
                          <li><a href="footer-transparent.html">Footer Transparent</a></li>
                      </ul>
                  </li>
              </ul>
          </li>

          <li>
              <a href="#">Integrations</a>

              <ul>
                  <li>
                      <a href="#">
                          <i class="far fa-newspaper"></i>
                          <span>Payout</span>
                      </a>

                      <ul>
                          <li><a [routerLink]="['/payout']" >DLocal Payout</a></li>
                          <li><a href="#">Flutterwave</a></li>
                      </ul>
                  </li>
                  <li>
                      <a href="#">
                          <i class="fab fa-wpforms"></i>
                          <span>Payin</span>
                      </a>

                      <ul>
                          <li><a [routerLink]="['/payin']">DLocal Payin</a></li>
                      </ul>
                  </li>
              </ul>
          </li>

          <li>
              <a href="#">Accounting</a>

              <ul>
                  <li>
                      <a href="#">
                          <i class="far fa-envelope"></i>
                          <span>Account Charts</span>
                      </a>

                      <ul>
                          <li><a href="#">Create Account Chart</a></li>
                          <li><a href="#">Create Product</a></li>
                          <li><a href="#">Configure Product</a></li>
                          <li><a href="#">View Chart of Accounts</a></li>
                          <li><a href="#">View Product</a></li>
                          <li><a href="#">View Configuration</a></li>
                          <li><a href="#">Delete Configurations</a></li>
                      </ul>
                  </li>
                  <li>
                      <a href="calendar.html">
                          <i class="far fa-calendar-alt"></i>
                          <span>Calendar</span>
                      </a>
                  </li>
                  <li>
                      <a href="chat.html">
                          <i class="far fa-comments"></i>
                          <span>Chat</span>
                      </a>
                  </li>
                  <li>
                      <a href="contacts.html">
                          <i class="far fa-address-book"></i>
                          <span>Contacts</span>
                      </a>
                  </li>
                  <li>
                      <a href="notes.html">
                          <i class="far fa-sticky-note"></i>
                          <span>Notes</span>
                      </a>
                  </li>
                  <li>
                      <a href="todo-list.html">
                          <i class="fa fa-tasks"></i>
                          <span>Todo List</span>
                      </a>
                  </li>
                  <li>
                      <a href="search-results.html">
                          <i class="fa fa-search"></i>
                          <span>Search Results</span>
                      </a>
                  </li>
              </ul>
          </li>


      </ul>
  </div>
  <!-- Sidebar Navigation End -->

  <!-- Sidebar Widgets Start -->

  <!-- Sidebar Widgets End -->
</aside>

import { PageHeaderComponent } from './Molecule/page-header/page-header.component';
import { DasboardComponent } from './Organism/dasboard/dasboard.component';
import { SidebarComponent } from './Molecule/sidebar/sidebar.component';
import { HeaderComponent } from './Molecule/header/header.component';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreatecurrencyComponent } from './Molecule/createcurrency/createcurrency.component';
import { CreatecurrencypageComponent } from './Organism/createcurrencypage/createcurrencypage.component';
import { FormsModule } from '@angular/forms';
import { UtilityService } from './Services/utility.service';
import { HttpHelperService } from './Services/http-helper.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HeadledgercoaComponent } from './Molecule/headledgercoa/headledgercoa.component';
import { DetailledgercoaComponent } from './Molecule/detailledgercoa/detailledgercoa.component';
import { HeadledgerpageComponent } from './Organism/headledgerpage/headledgerpage.component';
import { DetailledgerpageComponent } from './Organism/detailledgerpage/detailledgerpage.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PayinComponent } from './Molecule/payin/payin.component';
import { PayinpageComponent } from './Organism/payinpage/payinpage.component';
import { PayoutComponent } from './Molecule/payout/payout.component';
import { PayoutpageComponent } from './Organism/payoutpage/payoutpage.component';
import { LoginpageComponent } from './Organism/loginpage/loginpage.component';
import { LoginstandardComponent } from './Molecule/loginstandard/loginstandard.component';







@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    DasboardComponent,
    PageHeaderComponent,
    CreatecurrencyComponent,
    CreatecurrencypageComponent,
    HeadledgercoaComponent,
    DetailledgercoaComponent,
    HeadledgerpageComponent,
    DetailledgerpageComponent,
    PayinComponent,
    PayinpageComponent,
    PayoutComponent,
    PayoutpageComponent,
    LoginpageComponent,
    LoginstandardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [UtilityService, HttpHelperService, HttpClient, {provide : LocationStrategy , useClass: HashLocationStrategy} ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Payoutrequest } from './../Models/payoutrequest';
import { Injectable } from '@angular/core';
import { UtilityService } from './utility.service';
import { HttpClient } from '@angular/common/http';
import { CurrencyDTO } from '../Models/currency-dto';
import { Accountchart, AccountChartDetail } from '../Models/accountchart';
import { Currencylist } from '../Models/currencylist';
import { Payinreq } from '../Models/payinreq';
import { PayinResponse } from '../Models/payin-response';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {

  constructor(private uti: UtilityService, private http: HttpClient) { }

  createcurrency(info: CurrencyDTO) {
    return this.http.post(this.uti.createCurrencyURL, info);
  }
  createHeadLedger(info: Accountchart) {
    return this.http.post(this.uti.createCOAURL, info);
  }

  createDetailLedger(info: AccountChartDetail) {
    return this.http.post(this.uti.createCOAURL, info);
  }
  createpayin(payin: Payinreq) {
    return this.http.post<PayinResponse>(this.uti.payinURL, payin);
  }
  createpayout(payout: Payoutrequest) {
    return this.http.post(this.uti.payoutURL, payout);
  }
  getAllCurrencies() {
    return this.http.get<Currencylist[]>(this.uti.getaallcurrencyUrl);
  }
}

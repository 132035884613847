import { Logininfo } from './../../Models/logininfo';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-loginstandard',
  templateUrl: './loginstandard.component.html',
  styleUrls: ['./loginstandard.component.sass']
})
export class LoginstandardComponent implements OnInit {

  constructor(private route: Router) { }
  login: Logininfo;

  ngOnInit(): void {
    this.login = new Logininfo();
  }
  submitForm() {
    // console.log('Saved successfully..');
console.log(this.login.username);
    if ((this.login.username === 'errandadmin') &&  (this.login.password === 'pass123@'))
    {
      this.route.navigate(['/dashboard']);
    }
    else
     {
       Swal.fire('error', 'Invalid Credentials', 'error');
     }
   }
}

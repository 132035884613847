import { Payoutrequest } from './../../Models/payoutrequest';
import { Component, OnInit } from '@angular/core';
import { HttpHelperService } from 'src/app/Services/http-helper.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.sass']
})
export class PayoutComponent implements OnInit {
  constructor(private serv: HttpHelperService) { }
  payout: Payoutrequest;
  ngOnInit(): void {
     this.payout = new Payoutrequest();
  }
  submitForm() {
   // console.log('Saved successfully..');

    this.serv.createpayout(this.payout).subscribe (data => {
      Swal.fire('Success', 'Please payout has been created', 'success');
    },
    err => {
      Swal.fire('error', 'attempt to payout failed', 'error');
    }
    );
  }
  }

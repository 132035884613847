export class Accountchart {
    currencyCode: string;
    glCode: string;
    accountName: string;
    description: string;
    accountType: string;
}

export class AccountChartDetail extends Accountchart {
  groupGLCode: string;
}

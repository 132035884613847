import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CurrencyDTO } from 'src/app/Models/currency-dto';
import { HttpHelperService } from 'src/app/Services/http-helper.service';
import Swal from 'sweetalert2';
import { AccountChartDetail } from '../../Models/accountchart';
import { Currencylist } from '../../Models/currencylist';
@Component({
  selector: 'app-headledgercoa',
  templateUrl: './headledgercoa.component.html',
  styleUrls: ['./headledgercoa.component.sass']
})
export class HeadledgercoaComponent implements OnInit {
currencies: Currencylist[];
  constructor(private serv: HttpHelperService) { }
coa: AccountChartDetail;
  ngOnInit(): void {
     this.coa = new AccountChartDetail();
     this.serv.getAllCurrencies().subscribe( c => {
          this.currencies = c;
     });
  }
  submitForm() {
    console.log('Saved successfully..');
    console.log(this.coa);
    this.serv.createHeadLedger(this.coa).subscribe (data => {
      Swal.fire('Success', this.coa.accountName + ' was successfully created', 'success');
    },
    err => {
      Swal.fire('error', 'attempt to create ' + this.coa.accountName + 'failed..', 'error');
    }
    );
  }
  }

 <!-- Wrapper Start -->
 <div class="wrapper">
  <!-- Login Page Start -->
  <div class="m-account-w">
      <div class="m-account">
          <div class="row no-gutters">
              <div class="col-md-6">
                  <!-- Login Content Start -->
                  <div class="m-account--content-w" >
                      <div class="m-account--content">
                          <h2 class="h2">Don't have an account?</h2>

                      </div>
                  </div>
                  <!-- Login Content End -->
              </div>

              <div class="col-md-6">
                  <!-- Login Form Start -->
                  <div class="m-account--form-w">
                      <div class="m-account--form">
                          <!-- Logo Start -->
                          <div class="logo">
                             <h3>Administration</h3>
                          </div>
                          <!-- Logo End -->

                          <form method="POST">
                              <label class="m-account--title">Login to your account</label>

                              <div class="form-group">
                                  <div class="input-group">
                                      <div class="input-group-prepend">
                                          <i class="fas fa-user"></i>
                                      </div>

                                      <input id="login.username" type="text" [(ngModel)] = "login.username" name="login.username"  placeholder="Username" class="form-control"  required>
                                  </div>
                              </div>

                              <div class="form-group">
                                  <div class="input-group">
                                      <div class="input-group-prepend">
                                          <i class="fas fa-key"></i>
                                      </div>

                                      <input id="login.password" type="password" [(ngModel)] = "login.password" name="login.password"  placeholder="Password" class="form-control" autocomplete="off" required>
                                  </div>
                              </div>

                              <div class="m-account--actions">
                                  <a href="#" class="btn-link">Forgot Password?</a>

                                  <button type="submit" class="btn btn-rounded btn-info" (click)="submitForm()">Login</button>
                              </div>



                              <div class="m-account--footer">
                                  <p>&copy; 2020 ErrandPay</p>
                              </div>
                          </form>
                      </div>
                  </div>
                  <!-- Login Form End -->
              </div>
          </div>
      </div>
  </div>
  <!-- Login Page End -->
</div>
<!-- Wrapper End -->

import { DetailledgerpageComponent } from './Organism/detailledgerpage/detailledgerpage.component';
import { CreatecurrencyComponent } from './Molecule/createcurrency/createcurrency.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeadledgercoaComponent } from './Molecule/headledgercoa/headledgercoa.component';
import { HeadledgerpageComponent } from './Organism/headledgerpage/headledgerpage.component';
import { CreatecurrencypageComponent } from './Organism/createcurrencypage/createcurrencypage.component';
import { PayinpageComponent } from './Organism/payinpage/payinpage.component';
import { PayoutComponent } from './Molecule/payout/payout.component';
import { PayoutpageComponent } from './Organism/payoutpage/payoutpage.component';
import { LoginpageComponent } from './Organism/loginpage/loginpage.component';



const routes: Routes = [
 { path: 'dashboard',  component:  HeadledgerpageComponent},
 { path: '', component:  LoginpageComponent},
  { path: 'currency', component:  CreatecurrencypageComponent},
  { path: 'payin', component:  PayinpageComponent},
  { path: 'payout',  component:  PayoutpageComponent},
  {path: 'legderhead', component: HeadledgerpageComponent},
  {path: 'detailhead', component: DetailledgerpageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }



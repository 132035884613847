            <section class="page--header">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-lg-6">
                          <!-- Page Title Start -->
                          <h2 class="page--title h5">Currency Setup</h2>
                          <!-- Page Title End -->

                          <ul class="breadcrumb">
                              <li class="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                              <li class="breadcrumb-item active"><span>Currency</span></li>

                          </ul>
                      </div>

                      <div class="col-lg-6">
                          <!-- Summary Widget Start -->
                          <div class="summary--widget">
                              <div class="summary--item">
                                  <p class="summary--chart" data-trigger="sparkline" data-type="bar" data-width="5" data-height="38" data-color="#009378">2,9,7,9,11,9,7,5,7,7,9,11</p>

                                  <p class="summary--title">This Month</p>
                                  <p class="summary--stats text-green">2,371,527</p>
                              </div>

                              <div class="summary--item">
                                  <p class="summary--chart" data-trigger="sparkline" data-type="bar" data-width="5" data-height="38" data-color="#e16123">2,3,7,7,9,11,9,7,9,11,9,7</p>

                                  <p class="summary--title">Last Month</p>
                                  <p class="summary--stats text-orange">2,527,371</p>
                              </div>
                          </div>
                          <!-- Summary Widget End -->
                      </div>
                  </div>
              </div>
          </section>
          <!-- Page Header End -->

          <!-- Main Content Start -->
          <section class="main--content">
              <div class="row gutter-20">
                  <div class="col-md-12">
                      <!-- Panel Start -->
                      <div class="panel">
                          <div class="panel-heading">
                              <h3 class="panel-title">Create Currency</h3>
                          </div>
                          <form method="POST">
                          <div class="panel-content">
                              <div class="form-group">
                                  <label>
                                      <span class="label-text">Currency Name</span>
                                      <input type="text" [(ngModel)] = "currency.name" name="currency.name" placeholder="Name..." class="form-control">
                                  </label>
                              </div>

                              <div class="form-group">
                                  <label>
                                      <span class="label-text">Currency Code</span>
                                      <input type="text"  [(ngModel)] = "currency.code" name="currency.code" placeholder="Code..." class="form-control">
                                  </label>
                              </div>
                              <div class="form-group">
                                <label>
                                    <span class="label-text">Currency Symbol</span>
                                    <input type="text"  [(ngModel)] = "currency.symbol" name="currency.symbol" placeholder="Symbol..." class="form-control">
                                </label>
                            </div>

                              <input type="button" value="Submit" class="btn btn-sm btn-rounded btn-success" (click)="submitForm()">
                              <button type="button" class="btn btn-sm btn-rounded btn-outline-secondary">Cancel</button>
                          </div>
                          </form>
                      </div>


                      <!-- Panel End -->
                  </div>
              </div>
          </section>

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  baseUrl = 'http://198.38.85.211/corebanking';
  createCurrencyURL = this.baseUrl + '/api/Product/CreateCurrency';
  createCOAURL = this.baseUrl + '/api/Account/CreateAccountChart';
  getaallcurrencyUrl = this.baseUrl + '/api/Product/GetCurrencies';
  payinURL = 'http://198.38.85.211/dlocal/api/Administration/CreatePayin';
  payoutURL = 'http://198.38.85.211/dlocal/api/Administration/CreatePayout';

  constructor() { }
}

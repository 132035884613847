export class Payoutrequest {
    beneficiaryFirstName: string;
    beneficiaryLastName: string;
    amount: string;
    bankName: string;
    bankAccount: string;
    comment: string;
    bankBranch: string;
    phone: string;
  }
